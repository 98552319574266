import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/index",
    name: "index",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      title: "DigBoost - 一站式跨境电商AIGC运营服务平台",
    },
  },
  {
    path: "/dashboard",
    name: "dashbaord",
    component: () => import("@/views/DashboardView.vue"),
    meta: {
      title: "DigBoost | 工作台",
    },
    children: [
      {
        path: "",
        name: "dashboardIndex",
        component: () => import("@/views/DashboardIndexView.vue"),
      },
      {
        path: "shangpk",
        name: "shangpk",
        component: () => import("@/views/ShangpkView.vue"),
      },
      {
        path: "zhishik",
        name: "zhishik",
        component: () => import("@/views/ZhishikView.vue"),
      },
      {
        path: "videopf",
        name: "videopf",
        component: () => import("@/views/VideopfView.vue"),
      },
      {
        path: "tuwensc",
        name: "tuwensc",
        component: () => import("@/views/TuwenscView.vue"),

      },
      {
        path: "tupiancl",
        name: "tupiancl",
        component: () => import("@/views/TuPianclView.vue"),
      },
      {
        path: "digitalHuman",
        name: "digitalHuman",
        component: () => import("@/views/DigitalHumanView.vue"),
      },
      {
        path: "tts",
        name: "tts",
        component: () => import("@/views/TTSView.vue"),
        meta: {
          keepAlive: true,
        }
      },
      {
        path: "zhiboj",
        name: "zhiboj",
        component: () => import("@/views/ZhibojView.vue"),
      },
      {
        path: "shengc/:id",
        name: "shengc",
        component: () => import("@/views/ShengcView.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/price",
    name: "price",
    component: () => import("@/views/priceView.vue"),
    meta: {
      title: "DigBoost | 价格",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LogoView.vue"),
    meta: {
      title: "DigBoost - 一站式跨境电商AIGC运营服务平台",
    },
  },
  {
    path: "/team",
    name: "team",
    component: () => import("@/views/TeamView.vue"),
    meta: {
      title: "DigBoost | 企业团队",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
